import type { JSX } from 'react';
import { CodeEmbed as CodeEmbedComponent } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CodeEmbedProps = SliceComponentProps<Content.CodeEmbedSlice>;

const CodeEmbed = ({ slice }: CodeEmbedProps): JSX.Element => (
  <CodeEmbedComponent {...slice} />
);

export default CodeEmbed;
